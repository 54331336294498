import classNames from 'classnames';
import { ReactNode } from 'react';

import LandingSection from '../LandingSection/LandingSection';
import css from './ShowcaseCarousel.module.css';
import { ImageLazy, NamedLink } from 'components';
import { RouteName } from 'routing/routeConfiguration';

const ShowcaseCarousel: React.FC<{
  title?: ReactNode;
  itemClassName?: string;
  items: {
    title: ReactNode;
    imgSrc: string;
    imgBlurSrc?: string;
    linkParams?: {
      name: RouteName;
      to?: { search: string };
      params?: any;
    };
    button?: ReactNode;
  }[];
}> = ({ title, itemClassName, items = [] }) => {
  return (
    <LandingSection title={title}>
      {items.map(({ title, imgSrc, imgBlurSrc, linkParams, button }, i) => {
        const LinkComponent = linkParams ? NamedLink : 'div';

        return (
          <LinkComponent
            className={classNames(css.item, itemClassName)}
            key={i}
            {...(linkParams as any)}
          >
            <ImageLazy
              className={css.item__image}
              src={imgSrc}
              blurSrc={imgBlurSrc}
              alt={String(title)}
            />
            <div className={css.item__content}>
              <h3 style={{ fontSize: 'inherit' }}>{title}</h3>
              {button}
            </div>
          </LinkComponent>
        );
      })}
    </LandingSection>
  );
};

export default ShowcaseCarousel;
