import classNames from 'classnames';
import React, { ReactNode } from 'react';

import css from './LandingSection.module.css';
import { CarouselNavigationButtons } from 'components';

const LandingSection: React.FC<{
  title?: ReactNode;
  type?: 'with-padding' | 'end-to-end';
}> = ({ title, type = 'end-to-end', children }) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className={css.root}>
      {title && <h2 className={css.title}>{title}</h2>}
      <div
        className={classNames(css.content, {
          [css['content--with-padding']]: type === 'with-padding',
          [css['content--end-to-end']]: type === 'end-to-end',
        })}
        ref={carouselRef}
      >
        {children}
      </div>
      <CarouselNavigationButtons carouselRef={carouselRef} />
    </div>
  );
};

export default LandingSection;
