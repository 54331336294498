import { LayoutSingleColumn, ListingCard, MobileBottomNavigation, Page } from '../../components';
import ListingsSection from '../CustomLandingPage/components/ListingsSection/ListingsSection';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './ByFarLandingPage.module.css';
import ByFarCustomSection from './components/ByFarCustomSection/ByFarCustomSection';
import HeroCollectionSection from './components/HeroCollectionSection/HeroCollectionSection';
import { HowItWorks } from './components/HowItWorks/HowItWorks';
import ShowcaseCarousel from './components/ShowcaseCarousel/ShowcaseCarousel';
import appSettings from 'config/settings';
import LandingSection from 'containers/CustomLandingPage/components/LandingSection/LandingSection';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { DEFAULT_SORTING } from 'util/constants';

export const BY_FAR_BRAND_ID = appSettings.dev ? 2858 : 2854;

const ByFarLandingPage = () => {
  const scrollingDisabled = useIsScrollingDisabled();

  const { data: listingsData } = useGetListings({
    brandIds: [BY_FAR_BRAND_ID],
    preloved: true,
  });

  const allListings = listingsData?.pages[0]?.listings || [];
  const latestListings = allListings.slice(0, 24);

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<MobileBottomNavigation />}>
        <div className={css.wrapper}>
          <HeroCollectionSection />
          <LandingSection>
            <div className={css.byFarCircular}>
              <h1>By far circular</h1>
              <p>
                BY FAR CIRCULAR introduces an exclusive circular hub - a sanctuary where luxury
                meets legacy, and every transaction nurtures our planet. Sell what you no longer
                use, shop pre-loved and archive styles and sell iconic BY FAR pieces in a way that
                respects the planet.
                <br />
                <br />
                We dare to make a difference. Do you?
              </p>
            </div>
          </LandingSection>
          <ByFarCustomSection />
          <ShowcaseCarousel
            title="Top Sample Collections"
            items={[
              {
                title: <span style={{ color: 'var(--n-color-white)' }}>Bags</span>,
                imgSrc: 'https://cdn.thenold.com/images/Amber Black Circ Croco.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/images/Amber Black Circ Croco.blur.jpg',
                linkParams: {
                  name: 'SearchPage',
                  to: {
                    search: `brandIds=${BY_FAR_BRAND_ID}&category=bags&sortBy=${DEFAULT_SORTING}`,
                  },
                },
              },
              {
                title: <span style={{ color: 'var(--n-color-white)' }}>Shoes</span>,
                imgSrc: 'https://cdn.thenold.com/images/Gina White Nappa Leather.jpg',
                imgBlurSrc: 'https://cdn.thenold.com/images/Gina White Nappa Leather.blur.jpg',
                linkParams: {
                  name: 'SearchPage',
                  to: {
                    search: `brandIds=${BY_FAR_BRAND_ID}&category=shoes&sortBy=${DEFAULT_SORTING}`,
                  },
                },
              },
              {
                title: <span style={{ color: 'var(--n-color-white)' }}>Accessories</span>,
                imgSrc: 'https://cdn.thenold.com/images/Vic Brown Semi Patent Leather.jpg',
                imgBlurSrc:
                  'https://cdn.thenold.com/home/images/Vic Brown Semi Patent Leather.blur.jpg',
                linkParams: {
                  name: 'SearchPage',
                  to: {
                    search: `brandIds=${BY_FAR_BRAND_ID}&category=accessories&sortBy=${DEFAULT_SORTING}`,
                  },
                },
              },
            ]}
            itemClassName={css.showCaseItem__collections}
          />
          {Boolean(latestListings?.length) && (
            <ListingsSection title="Pre-loved By Far by You">
              {latestListings.map(l => (
                <ListingCard key={l.id.uuid} listing={l} />
              ))}
            </ListingsSection>
          )}
          <LandingSection title="How it works">
            <HowItWorks />
          </LandingSection>
        </div>
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

export default ByFarLandingPage;
