import * as Accordion from '@radix-ui/react-accordion';

import css from './HowItWorks.module.css';
import { IconChevronDown } from 'assets/icons';
import { useMediaQueries } from 'hooks/useMediaQueries';

export const HowItWorks = () => {
  const isDesktop = useMediaQueries({ viewport: 'medium' });

  return (
    <Accordion.Root type="single" className={css.accordionRoot} collapsible>
      {getContent(isDesktop).map(filter => (
        <Accordion.Item value={filter.label} key={filter.label}>
          <Accordion.Header>
            <Accordion.Trigger className={css.accordionItem__header}>
              <span>{filter.label}</span>
              <IconChevronDown />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className={css.accordionItem__content}>
            {filter.content}
            <div style={{ height: 'var(--n-size-2)' }} />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};

const getContent = (isDesktop: boolean) => [
  {
    label: 'What is BY FAR CIRCULAR?',
    content: (
      <p>
        BY FAR CIRCULAR is a dedicated resale hub, exclusively featuring BY FAR products, where
        users can buy and sell pre-loved and sample BY FAR items. The platform is powered by the
        NOLD, a tech company with a sustainability focus. The NOLD partners with brands to empower
        them to become part of the circular fashion in the most effective and aesthetic way. Through
        BY FAR CIRCULAR, the NOLD empowers the brand to give a second life to its products, promote
        longevity, and have a fair share in reducing the fashion industry's ecological footprint.
      </p>
    ),
  },
  {
    label: 'How to list an item?',
    content: (
      <div style={{ display: 'grid', gap: 'var(--n-size-2)' }}>
        <p>
          Listing an item on the BY FAR CIRCULAR could not be easier, and we're here to guide you
          through each step.
        </p>
        {isDesktop ? (
          <p>
            Just click on "Sell" in the top left corner of the home page, or navigate to the Profile
            icon located in the upper right corner of the screen. From there, select My Listings -
            {'>'} Add Listing.
          </p>
        ) : (
          <p>
            On the home page, click the "Plus" button located in the menu bar at the bottom corner
            of the screen.
          </p>
        )}

        <p>
          Simply enter <b>"BY FAR"</b> in the search text box, and the brand’s product database will
          be automatically loaded. You can find your specific product by searching by name of the
          product (“Miranda”, “Tanya”, “Baby Amber”, etc.) or filtering by department, category,
          colour, type, and subtype. Select the item you want to sell and click "Next."
        </p>

        <p>
          Now you are prompted to add at least two current photos of your item. Keep in mind that if
          the item has visible defects, missing parts, visible signs of wear, they need to be shown
          on the photos. Authenticity and transparency are key here, so ensure the images accurately
          represent your product's condition.
        </p>

        <p>
          Indicate the current condition of your item, ensuring accuracy in your representation and
          insert the price at which you wish to sell your item.
        </p>

        <p>
          In the next step you will see that all the details from the brand - title, description,
          colour, size, category, type, subtype, are automatically pre-filled, thanks to the brand's
          product database. You can share any extra information about your item, or a great story it
          goes with in the description.
        </p>

        <p>
          If you haven't filled in your shipping address when setting up your account, provide
          information on where you will be shipping the item from.
        </p>

        <p>
          Preview your listing, ensuring all details are accurate, and then hit <b>Submit</b>.
        </p>
      </div>
    ),
  },
  {
    label: 'What if my item is not in the database?',
    content: (
      <p>
        If you can't find the item you'd like to sell in BY FAR's product database, please contact
        our customer service team at <a href="mailto:hello@thenold.com">hello@thenold.com</a>, and
        we'll be happy to assist you.
      </p>
    ),
  },
  {
    label: 'Can I list an item if I live outside the UK?',
    content: (
      <p>
        Initially, listing on BY FAR CIRCULAR is exclusively accessible to users within the United
        Kingdom. However, our vision for the NOLD is global, aiming to empower users worldwide to
        share their pre-loved items. We anticipate expanding the platform's reach, allowing users
        from around the globe to list their items in the near future.
      </p>
    ),
  },
  {
    label: 'How do I know if my item is SOLD?',
    content: (
      <p>
        When someone purchases your item, the NOLD automatically marks it as SOLD. If you sold it on
        another platform, please contact us, and we'll assist you in closing your listing.
      </p>
    ),
  },
  {
    label: 'How do I ship the item?',
    content: (
      <p>
        All orders within the United Kingdom will be handled by our trusted shipping partner, Evri.
        When a customer requests to buy an item from your profile, you need to accept the order.
        Once confirmed, a QR code will appear in the chat window and be sent to your email. This
        allows you to print the shipping label at the nearest Evri parcel shop. You will also
        receive a tracking number to monitor the parcel's journey. Please note that you have a 7-day
        window to ship your item. If you miss this deadline, the transaction will be automatically
        declined.
      </p>
    ),
  },
  {
    label: 'How do payouts work?',
    content: (
      <p>
        After a successful sale, the seller can choose their preferred payment method in their NOLD
        Wallet. Sellers have the option to receive 90% of the transaction value in cash, or 100% in
        store credit to shop at <a href="https://byfar.com">byfar.com</a>.
      </p>
    ),
  },
  {
    label: 'I am a buyer, what happens if the seller never ships the item?',
    content: (
      <p>
        When you request to buy an item and the seller confirms the transaction, they have 7 days to
        send the item. If they miss this deadline, your order will be automatically cancelled, and
        you’ll receive a full refund.
      </p>
    ),
  },
  {
    label: 'Will I be charged if the seller rejects my order?',
    content: (
      <p>
        No, the amount is only transferred once the seller confirms the order, and the item is
        shipped. In case the seller rejects the order, or confirms it but never ships it, the
        temporary blocking of the amount will be released, and you will not incur any charges for
        the unsuccessful transaction.
      </p>
    ),
  },
  {
    label: 'What if I want to return an item?',
    content: (
      <div style={{ display: 'grid', gap: 'var(--n-size-2)' }}>
        <p>
          If you purchased a BY FAR item from another user on the platform and it doesn't fit or you
          changed your mind, we can help re-list it on your profile for resale. Otherwise, all
          purchases made on BY FAR CIRCULAR are final and cannot be returned unless the item is
          damaged or significantly different from the seller's description.
        </p>
        <p>
          This policy does not apply to purchases from our BY FAR sample sale collection, provided
          by the NOLD. Users have the right to return these items for a full refund within 14 days.
        </p>
      </div>
    ),
  },
];
