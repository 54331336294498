import classNames from 'classnames';

import css from './ByFarCustomSection.module.css';
import { Button, ImageLazy, NamedLink } from 'components';
import { ConditionType } from 'config/configListing';
import { BY_FAR_BRAND_ID } from 'containers/ByFarLandingPage/ByFarLandingPage';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { DEFAULT_SORTING } from 'util/constants';

const ByFarCustomSection = () => {
  const isDesktop = useMediaQueries({ viewport: 'medium' });

  const text = (
    <div className={css.text}>
      <h2>
        Sell your by far, <br />
        Get 100%
      </h2>
      <div className={css.text__paragraphContainer}>
        <p>1. Click on RE-SELL and enjoy the fastest BY FAR Al listing globally</p>
        <p>2. Sell and ship your item</p>
        <p>3. Claim 90% of the resale in cash or 100% in store credit for byfar.com</p>
      </div>
      <NamedLink name="ResellLandingPage" to={{ search: `?brandId=${BY_FAR_BRAND_ID}` }}>
        <Button>RE-SELL</Button>
      </NamedLink>
    </div>
  );

  return (
    <div className={css.root}>
      <div className={css.background}>
        <ImageLazy
          src="https://cdn.thenold.com/images/Mini Rachel Cyclamen Circ Croco.jpg"
          blurSrc="https://cdn.thenold.com/images/Mini Rachel Cyclamen Circ Croco.blur.jpg"
        />
        {!isDesktop && text}
      </div>
      <div className={css.content}>{isDesktop && text}</div>
      <div className={css.shopCircular}>
        <h2>
          Shop
          <br />
          circular
        </h2>
        <NamedLink name="SearchPage" to={{ search: `?brandIds=${BY_FAR_BRAND_ID}` }}>
          <Button>Shop all</Button>
        </NamedLink>
      </div>
      <NamedLink
        name="SearchPage"
        to={{
          search: `?brandIds=${BY_FAR_BRAND_ID}&condition=${ConditionType.SampleSale}&sortBy=${DEFAULT_SORTING}`,
        }}
        className={classNames(css.imageContainer, css.cardText)}
      >
        <ImageLazy
          src="https://cdn.thenold.com/images/Freddy Black Spray Print, Mini Rachel Black Spray Print, Lulu Pink Spray Print and Billy Ivory Spray Print.jpg"
          blurSrc="https://cdn.thenold.com/images/Freddy Black Spray Print, Mini Rachel Black Spray Print, Lulu Pink Spray Print and Billy Ivory Spray Print.blur.jpg"
        />
        <h2>
          Shop
          <br />
          archive
        </h2>
      </NamedLink>
      <NamedLink
        name="SearchPage"
        to={{
          search: `?brandIds=${BY_FAR_BRAND_ID}&preloved=true`,
        }}
        className={classNames(css.imageContainer, css.cardText)}
      >
        <ImageLazy
          src="https://cdn.thenold.com/images/Soho Ivory Gloss.jpg"
          blurSrc="https://cdn.thenold.com/images/Soho Ivory Gloss.blur.jpg"
        />
        <h2>
          Shop
          <br />
          pre-loved
        </h2>
      </NamedLink>
    </div>
  );
};

export default ByFarCustomSection;
