import css from './HeroCollectionSection.module.css';
import { Button, ImageLazy, NamedLink } from 'components';
import { BY_FAR_BRAND_ID } from 'containers/ByFarLandingPage/ByFarLandingPage';

const HeroCollectionSection = () => {
  const imgProps = {
    src: 'https://cdn.thenold.com/images/BF_Shoes3.jpg',
    blurSrc: 'https://cdn.thenold.com/BF_Shoes3.blur.jpg',
  };

  return (
    <div className={css.root}>
      <ImageLazy {...imgProps} alt="By Far" />
      <div className={css.container}>
        <h1>BY FAR CIRCULAR</h1>
        <span style={{ display: 'flex', gap: 'var(--n-size-2)' }}>
          <NamedLink name="SearchPage" to={{ search: `?brandIds=${BY_FAR_BRAND_ID}` }}>
            <Button>Re-Shop</Button>
          </NamedLink>
          <NamedLink name="ResellLandingPage" to={{ search: `?brandId=${BY_FAR_BRAND_ID}` }}>
            <Button>Re-Sell</Button>
          </NamedLink>
        </span>
      </div>
    </div>
  );
};

export default HeroCollectionSection;
