import React from 'react';

import LandingSection from '../LandingSection/LandingSection';
import { ListingsCarousel } from 'components';

const ListingsSection = ({ title, children }) => {
  return (
    <LandingSection title={title}>
      <ListingsCarousel>{children}</ListingsCarousel>
    </LandingSection>
  );
};

export default ListingsSection;
